.artPage {
  padding: 84px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .art-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h4:not(.contact-form .section-title) {
    margin-top: 20.8rem;
  }

  h3 {
    margin-bottom: 10.4rem;
  }

  .art-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    margin-bottom: 24.7rem;

    img {
      border: 2px solid var(--cyan);
      width: 55rem;
      height: 60rem;
    }
  }
}

/* Media Queries */

@media only screen and (max-width: 299px) {
  .artPage {
    padding: 94px 32px 42px;
    .art-gallery {
      img {
        height: 240px;
        width: 220px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .artPage {
    .art-container {
      padding: 0 16rem;
    }
    .art-gallery {
      img {
        height: 280px;
        width: 260px;
      }
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
  .artPage {
    .art-gallery {
      img {
        height: 320px;
        width: 300px;
      }
    }
  }
}

@media only screen and (max-width: 499px) {
  .artPage {
    .art-gallery {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8rem;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 599px) {
  .artPage {
    .art-container {
      padding: 0 10.4rem;
    }
    .art-gallery {
      grid-template-columns: 180px 180px;
      img {
        height: 200px;
        width: 180px;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .artPage {
    .art-gallery {
      gap: 38px;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 699px) {
  .artPage {
    .art-gallery {
      img {
        width: 265px;
        height: 289px;
      }
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 799px) {
  .artPage {
    .art-gallery {
      img {
        width: 308px;
        height: 336px;
      }
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 999px) {
  .artPage {
    .art-gallery {
      img {
        width: 352px;
        height: 384px;
      }
    }
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .artPage {
    .art-gallery {
      img {
        width: 440px;
        height: 480px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1699px) {
  .artPage {
    padding: 16rem;
    .art-container {
      padding: 0 10.4rem;
      .art-gallery {
        grid-template-columns: 55rem 55rem;
      }
    }
  }
}

@media only screen and (min-width: 1700px) {
  .artPage {
    .art-container {
      padding: 0 16rem;
      .art-gallery {
        grid-template-columns: 57rem 57rem;
        gap: 9.6rem;
        img {
          width: 57rem;
          height: 62rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2499px) {
  .artPage {
    padding: 20.8rem;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 2799px),
  (min-width: 3000px) {
  .artPage {
    padding: 32rem;
  }
}

@media only screen and (min-width: 2800px) and (max-width: 2999px) {
  .artPage {
    padding: 48rem;
  }
}
