.about-section {
  border: 8px solid var(--gold);
  background-color: #3a3c46;
  padding: 27rem 10.4rem 4rem;
  margin-top: 24rem;

  @media (max-width: 399px) {
    padding: 32px 12px;
  }
  @media (min-width: 400px) and (max-width: 599px) {
    padding: 48px 24px 16px;
  }
  @media (max-width: 599px) {
    margin-top: 104px;
  }
  @media (min-width: 600px) and (max-width: 799px) {
    padding: 64px 12px 64px 24px;
    margin-top: 208px;
  }
  @media (min-width: 800px) and (max-width: 999px) {
    padding: 64px 24px;
    margin-top: 400px;
  }
  @media (min-width: 1000px) and (max-width: 1299px) {
    padding: 18rem 4.8rem 4rem;
  }
  @media (min-width: 5000px) {
    margin-top: 0;
  }
}

.about-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 800px) and (max-width: 1299px) {
    align-items: center;
  }
}

// About me img

.about-pic {
  display: flex;
  flex-direction: column;
  width: 59rem;
  height: 80rem;
  align-items: flex-start;
  margin-bottom: 15%;
  object-fit: cover;
  @media (max-width: 399px) {
    width: 90%;
    height: 100%;
  }
  @media (min-width: 400px) and (max-width: 599px) {
    width: 70%;
    height: 60%;
  }
  @media (min-width: 600px) and (max-width: 799px) {
    width: 48%;
    height: 50%;
  }
  @media (min-width: 600px) and (max-width: 999px) {
    margin-bottom: 0;
  }
  @media (min-width: 800px) and (max-width: 1299px) {
    width: 40%;
    height: 40%;
  }
}

// Textbox

.text-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 599px) {
    margin-top: -72px;
  }
}

.bio {
  width: 70rem;
  height: 67.8rem;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 205%;
  z-index: 1;
  color: var(--white);
  margin-left: -1rem;

  @media (max-width: 399px) {
    font-size: 2rem;
  }
  @media (min-width: 400px) and (max-width: 599px) {
    font-size: 2.3rem;
  }
  @media (min-width: 600px) and (max-width: 799px) {
    font-size: 1.8rem;
  }
  @media (max-width: 1299px) {
    width: 100%;
    height: 100%;
  }
}

// Signature
.signature {
  width: 50%;
  margin: 2rem 0 0 -10rem;
  transform: rotate(-10deg);

  @media (max-width: 999px) {
    display: none;
  }

  @media (max-width: 1299px) {
    width: 40%;
  }
}
