.services-section {
  padding-bottom: 200px;
  padding: 50px;
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
