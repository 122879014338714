#root {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.bookPage {
  .book-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 16rem 0;

    @media (max-width: 599px) {
      margin: 0;
    }
    @media (min-width: 600px) and (max-width: 999px) {
      margin: 88px 0;
    }
    @media (min-width: 1000px) and (max-width: 1999px) {
      margin: 160px 0;
    }
    @media (min-width: 2000px) and (max-width: 2999px) {
      margin: 208px 0;
    }

    .page-heading-container {
      margin-bottom: 4rem;

      @media (max-width: 599px) {
        display: none;
      }
      @media (min-width: 1000px) and (max-width: 1299px) {
        transform: translateX(-104px);
      }
      @media (min-width: 1300px) and (max-width: 1999px) {
        transform: translateX(-11rem);
      }
      @media (min-width: 2000px) and (max-width: 2999px) {
        transform: translateX(-4.8rem);
      }
    }

    .book-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .calendar {
      text-align: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 699px) {
        margin: 16rem 0;
      }
      @media (min-width: 700px) {
        margin-bottom: 16rem;
      }
    }
  }

  footer {
    position: relative;
  }
}
