.positive-quotes-container {
  .quote-container {
    color: var(--bg-lightest);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.6em;
    text-align: center;
  }

  .positive-quote {
    font-size: 4.8rem;
    line-height: 7.2rem;
    transition: opacity 1500ms ease-in-out;
  }

  .quote-author {
    font-size: 2.4rem;
    line-height: 3.2rem;
    transition: opacity 1500ms ease-in-out;
  }
}

/* Media Queries */

@media only screen and (max-width: 299px) {
  .positive-quotes-container {
    .positive-quote {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .positive-quotes-container {
    .positive-quote {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .positive-quotes-container {
    .positive-quote {
      line-height: 28px;
    }
  }
}

@media only screen and (max-width: 799px) {
  .positive-quotes-container {
    .positive-quote {
      line-height: 35px;
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 899px) {
  .positive-quotes-container {
    .positive-quote {
      line-height: 46px;
    }
  }
}
