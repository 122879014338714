.hero-section {
  background: url(abm-hero.png), rgba(33, 37, 41, 0.8);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  border: 8px solid var(--gold);
  border-bottom: none;
  height: 106.2rem;

  @media (max-width: 399px) {
    height: 360px;
    border-width: 4px;
    border-bottom: 4px solid var(--gold);
  }
  @media (min-width: 400px) and (max-width: 999px) {
    border-bottom: 6px solid var(--gold);
  }
  @media (max-width: 599px) {
    height: 400px;
    border-width: 6px;
  }
  @media (min-width: 600px) and (max-width: 799px) {
    height: 520px;
  }
  @media (min-width: 800px) and (max-width: 999px) {
    height: 760px;
  }
  @media (min-width: 1800px) and (max-width: 1999px) {
    height: 1160px;
  }
  @media only screen and (min-width: 2100px) {
    height: 120rem;
  }
}

.hero-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 0.5fr 1fr;
  column-gap: 2.4rem;
  row-gap: 0;
  padding-left: 13rem;

  @media (max-width: 599px) {
    padding-left: 48px;
    grid-template-rows: 110px 55px 110px;
  }
  @media (min-width: 600px) and (max-width: 799px) {
    padding-left: 72px;
    grid-template-rows: 185px 92px 185px;
  }
  @media (min-width: 800px) and (max-width: 999px) {
    padding-left: 80px;
    grid-template-rows: 239px 119px 239px;
  }
}

.hero-heading {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 8;
  grid-row: 3;
  z-index: 1;

  @media only screen and (min-width: 2100px) {
    transform: translateY(13rem);
  }
}

h1 {
  font-weight: 700;
  font-size: 9.9rem;
  color: var(--white);

  @media (max-width: 499px) {
    font-size: 8.2rem;
  }
}

.subheading {
  font-weight: 400;
  font-size: 3.3rem;
  color: var(--white);
  text-transform: capitalize;

  @media (max-width: 499px) {
    font-size: 2.8rem;
  }
}

.book:link,
.book:visited {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  font-size: 2.3rem;
  width: 26.3rem;
  height: 6rem;
  color: #1c1e22;
  text-decoration: none;
  margin-top: 9.6rem;
  transition: all ease-in-out 150ms;
  background-color: var(--white);
  border: none;

  @media (max-width: 599px) {
    padding: 8px;
    font-size: 9px;
    height: 24px;
    width: 105px;
    margin-top: 40px;
  }
  @media (min-width: 600px) and (max-width: 799px) {
    margin-top: 40px;
    width: 160px;
    height: 36px;
  }
  @media (min-width: 800px) and (max-width: 999px) {
    margin-top: 64px;
    width: 200px;
  }
}

.book:hover,
.book:active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
