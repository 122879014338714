.makeupPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.makeup-container {
  padding-top: 120px;

  img {
    border-color: var(--white);
  }

  img:nth-child(8) {
    object-position: center top;
  }

  img:nth-child(15) {
    object-position: center -3rem;
  }

  img:nth-child(16) {
    object-position: center -2rem;
  }
}

/* Media Queries */

@media only screen and (max-width: 299px) {
  .makeup-container {
    padding: 120px 4.8rem 0;
  }

  .gallery-container {
    img:nth-child(16) {
      object-position: center;
    }
  }
}
