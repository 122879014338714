@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

:root {
  --white: #fffcf2;
  --gold: #b6984f;
  --cyan: #3bc9db;
  --red: #970c0c;
  --bg-color: #212529;
  --bg-light: #3a3c46;
  --bg-lightest: #686c7e;
  --desktop-margin: 13rem;
  --transition: all 150ms ease-in-out;
}

html {
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  box-shadow: 0 0 0 0.3rem #b6984f66;
  outline: none;
}

body {
  background-color: var(--bg-color);
  font-family: "Playfair Display", serif;
  overflow: hidden;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

/* Media Queries */

@media only screen and (max-width: 399px) {
  html:not(.homePage) {
    font-size: 25%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
  html:not(.homePage) {
    font-size: 23%;
  }
}

@media only screen and (min-width: 500px) and (max-width: 599px) {
  html:not(.homePage) {
    font-size: 25%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 699px) {
  html:not(.homePage) {
    font-size: 30%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 799px) {
  html:not(.homePage) {
    font-size: 35%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 899px) {
  html:not(.homePage) {
    font-size: 40%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 999px) {
  html:not(.homePage) {
    font-size: 45%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  html:not(.homePage) {
    font-size: 50%;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1199px) {
  html:not(.homePage) {
    font-size: 55%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  html:not(.homePage) {
    font-size: 60%;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2999px) {
  html:not(.homePage) {
    font-size: 85%;
  }
}

@media only screen and (min-width: 3000px) and (max-width: 3999px) {
  html:not(.homePage) {
    font-size: 120%;
  }
}

@media only screen and (min-width: 4000px) and (max-width: 4999px) {
  html:not(.homePage) {
    font-size: 170%;
  }
}

@media only screen and (min-width: 5000px) and (max-width: 5999px) {
  html:not(.homePage) {
    font-size: 180%;
  }
}

@media only screen and (min-width: 6000px) and (max-width: 6999px) {
  html:not(.homePage) {
    font-size: 190%;
  }
}

@media only screen and (min-width: 7000px) and (max-width: 7999px) {
  html:not(.homePage) {
    font-size: 200%;
  }
}

@media only screen and (min-width: 8000px) and (max-width: 8999px) {
  html:not(.homePage) {
    font-size: 210%;
  }
}

@media only screen and (min-width: 9000px) and (max-width: 9999px) {
  html:not(.homePage) {
    font-size: 220%;
  }
}
