.contact-form {
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50vw;

  @media (max-width: 699px) {
    width: 85%;
  }
  @media (min-width: 700px) and (max-width: 999px) {
    width: 100%;
  }
  @media only screen and (min-width: 700px) and (max-width: 899px) {
    padding: 0 80px;
  }
  @media only screen and (min-width: 900px) and (max-width: 999px) {
    padding: 0 88px;
  }
  @media (min-width: 1000px) and (max-width: 1399px) {
    width: 64rem;
  }

  label {
    color: var(--white);
    font-size: 1.9rem;
    @media (max-width: 599px) {
      font-size: 2.3rem;
    }
  }

  input,
  textarea {
    background-color: var(--bg-light);
    border: none;
    font-size: 1.9rem;
    color: var(--white);
  }

  input {
    height: 4.8rem;
    padding: 0 1rem;
  }

  textarea {
    padding: 5px 1rem;
  }

  input,
  .submit-btn {
    @media only screen and (min-width: 300px) and (max-width: 499px) {
      height: 32px;
      font-size: 10px;
    }
    @media only screen and (min-width: 500px) and (max-width: 699px) {
      height: 40px;
      font-size: 12px;
    }
    @media only screen and (min-width: 700px) and (max-width: 999px) {
      height: 48px;
      font-size: 14px;
    }
  }

  .submit-btn {
    background-color: var(--white);
    color: #1c1e22;
    font-family: "Playfair Display", serif;
    font-size: 1.9rem;
    font-weight: 600;
    padding: 1.3rem 4rem;
    border: none;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(10.4rem);

    @media only screen and (max-width: 299px) {
      height: 28px;
      font-size: 8px;
    }
    @media (max-width: 599px) {
      font-size: 2.3rem;
    }
  }

  .submit-btn:hover,
  .submit-btn:active {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  }

  .name-textbox,
  .email-textbox,
  .phone-textbox,
  .message-textbox {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .name-textbox,
  .email-textbox {
    margin-bottom: 5.6rem;

    @media only screen and (min-width: 300px) and (max-width: 499px) {
      margin-bottom: 22px;
    }
    @media only screen and (min-width: 500px) and (max-width: 999px) {
      margin-bottom: 40px;
    }
  }

  .message-textbox {
    transform: translateY(6.4rem);

    @media (max-width: 599px) {
      transform: translateY(24px);
    }
    @media (min-width: 600px) and (max-width: 999px) {
      transform: translateY(32px);
    }
    @media (min-width: 1000px) and (max-width: 1999px) {
      transform: translateY(40px);
    }
    @media (min-width: 2000px) and (max-width: 2999px) {
      transform: translateY(56px);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    width: 50vw;

    @media (max-width: 599px) {
      margin-top: 32px;
    }
    @media (max-width: 999px) {
      width: 100%;
    }
    @media (min-width: 1000px) and (max-width: 1399px) {
      width: 64rem;
    }
  }

  .contact-form h4,
  .contact-form h2 {
    margin: 0;
  }

  .form-title {
    display: flex;
    flex-direction: column;
  }
}
