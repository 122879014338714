.bridalPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bridal-container {
  // padding-top: 24.7rem;

  img {
    border-color: var(--white);
  }

  img:nth-child(3) {
    object-position: center !important;
  }

  img:nth-child(5) {
    object-position: center;
  }

  img:nth-child(7),
  img:nth-child(8) {
    object-position: center top;
  }
}

/* Media Queries */

@media only screen and (max-width: 299px) {
  .bridal-container {
    padding: 120px 4.8rem 0;
  }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .bridal-container {
    padding: 120px 16rem 0;
  }
}

@media only screen and (min-width: 400px) {
  .bridal-container {
    padding-top: 120px;
  }
}
