footer {
  height: 35.2rem;
  margin: 24.7rem 13rem 6.4rem;
  border-top: 1px solid #1c1d22;

  @media only screen and (max-width: 399px) {
    height: 180px;
  }
  @media only screen and (min-width: 400px) and (max-width: 599px) {
    height: 200px;
  }
  @media only screen and (max-width: 599px) {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .footer-logo {
    height: 15rem;
    width: 15rem;
    transform: translateX(-15px);

    @media only screen and (max-width: 399px) {
      width: 96px;
      height: 96px;
    }
    @media only screen and (min-width: 400px) and (max-width: 599px) {
      width: 108px;
      height: 108px;
    }
    @media only screen and (max-width: 599px) {
      transform: translateX(0);
    }
    @media only screen and (min-width: 600px) and (max-width: 799px) {
      height: 60px;
      width: 60px;
    }
  }

  .firstCol,
  .secondCol ul,
  .thirdCol ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .firstCol {
    margin-top: -3.1rem;

    @media only screen and (max-width: 599px) {
      align-items: center;
    }

    p {
      color: var(--white);
      font-size: 1.9rem;
      width: 15.5rem;

      @media only screen and (max-width: 399px) {
        font-size: 10px;
      }
      @media only screen and (min-width: 400px) and (max-width: 599px) {
        font-size: 12px;
      }
      @media only screen and (max-width: 599px) {
        width: fit-content;
      }
      @media only screen and (min-width: 600px) and (max-width: 699px) {
        width: 75px;
      }
      @media only screen and (min-width: 700px) and (max-width: 799px) {
        width: 85px;
      }
    }
  }

  .secondCol,
  .thirdCol {
    @media only screen and (max-width: 599px) {
      display: none;
    }
  }

  .secondCol ul,
  .thirdCol ul {
    gap: 1.6rem;
    margin-top: 2.4rem;
  }

  ion-icon {
    font-size: 4rem;
    color: var(--white);
    transition: all ease-in-out 150ms;

    @media only screen and (max-width: 399px) {
      font-size: 18px;
    }
    @media only screen and (min-width: 400px) and (max-width: 599px) {
      font-size: 20px;
    }
  }

  ion-icon:hover,
  ion-icon:active {
    color: var(--gold);
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-top: 3.2rem;
  }

  .cols {
    display: flex;
    gap: 20rem;
    margin-top: 6.4rem;
    align-items: flex-start;
    justify-content: center;

    @media only screen and (max-width: 599px) {
      flex-direction: column;
      gap: 0;
    }
    @media only screen and (min-width: 600px) and (max-width: 799px) {
      gap: 80px;
    }
  }

  .col-heading {
    color: var(--gold);
    font-size: 2.3rem;
    font-weight: 500;
  }

  .col-link a:link,
  .col-link a:visited {
    color: var(--white);
    font-size: 1.9rem;
    transition: all ease-in-out 150ms;
    text-decoration: none;
  }

  .col-link a:hover,
  .col-link a:active {
    text-decoration: underline;
  }
}
