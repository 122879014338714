.gallery-container {
  display: grid;
  place-items: center;
  gap: 2.4rem;
  margin-top: 10.4rem;

  img {
    object-fit: cover;
    border: 2px solid;
  }
}

@media only screen and (max-width: 299px) {
  .makeup-container,
  .sfx-container,
  .bridal-container {
    .gallery-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 230px;
        height: 180px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .makeup-container,
  .sfx-container {
    .gallery-container {
      img {
        height: 120px;
        width: 125px;
      }

      :nth-child(3) {
        height: 261px !important;
      }

      :nth-child(5) {
        width: 273px !important;
      }

      :nth-child(11) {
        height: 261px !important;
      }

      :nth-child(16) {
        width: 273px !important;
      }
    }
  }

  .bridal-container {
    .gallery-container {
      grid-template-columns: 125px 125px;
      img {
        height: 120px;
        width: 125px;
      }

      :nth-child(3) {
        height: 250px !important;
      }

      :nth-child(5) {
        width: 260px !important;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 499px) {
  .makeup-container,
  .sfx-container,
  .bridal-container {
    .gallery-container {
      :nth-child(3) {
        grid-area: 2 / 1 / 4 / 2;
      }

      :nth-child(5) {
        grid-area: 5 / 1 / 6 / 3;
      }
    }
  }

  .makeup-container,
  .sfx-container {
    .gallery-container {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(10, 1fr);

      :nth-child(11) {
        grid-area: 7 / 2 / 9 / 3;
      }

      :nth-child(16) {
        grid-area: 10 / 1 / 11 / 3;
      }
    }
  }

  .bridal-container {
    .gallery-container {
      grid-template-rows: repeat(6, 1fr);
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
  .makeup-container,
  .sfx-container,
  .bridal-container {
    .gallery-container {
      img {
        height: 145px;
        width: 175px;
      }

      :nth-child(3) {
        height: 310px !important;
      }

      :nth-child(5) {
        width: 370px !important;
      }

      :nth-child(11) {
        height: 310px !important;
      }

      :nth-child(16) {
        width: 370px !important;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 599px) {
  .gallery-container {
    img {
      height: 130px;
      width: 140px;
    }

    :nth-child(3) {
      height: 282px !important;
    }

    :nth-child(5) {
      width: 298px !important;
    }

    :nth-child(11) {
      height: 282px !important;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 699px) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .makeup-container,
  .sfx-container,
  .bridal-container {
    .gallery-container {
      grid-template-rows: repeat(6, 1fr);

      :nth-child(3) {
        grid-area: 1 / 3 / 3 / 4;
      }

      :nth-child(5) {
        grid-area: 3 / 1 / 4 / 3;
      }

      :nth-child(11) {
        grid-area: 5 / 2 / 7 / 3;
      }

      :nth-child(16) {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 699px) {
  .makeup-container,
  .sfx-container,
  .bridal-container {
    .gallery-container {
      img {
        height: 154px;
        width: 180px;
      }

      :nth-child(3) {
        height: 320px !important;
      }

      :nth-child(5) {
        width: 371px !important;
      }

      :nth-child(11) {
        height: 320px !important;
      }

      :nth-child(16) {
        width: 371px !important;
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .gallery-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 700px) and (max-width: 899px) {
  .gallery-container {
    img {
      height: 129px;
      width: 146px;
    }

    :nth-child(3) {
      height: 276px !important;
    }

    :nth-child(5) {
      width: 310px !important;
    }

    :nth-child(11) {
      height: 276px !important;
    }

    :nth-child(16) {
      width: 310px !important;
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 1299px) {
  .gallery-container {
    :nth-child(3) {
      grid-area: 1 / 3 / 3 / 4;
    }

    :nth-child(5) {
      grid-area: 3 / 1 / 4 / 3;
    }

    :nth-child(11) {
      grid-area: 3 / 4 / 5 / 5;
    }

    :nth-child(16) {
      grid-area: 5 / 2 / 6 / 4;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1099px) {
  .gallery-container {
    img {
      height: 145px;
      width: 164px;
    }

    :nth-child(3) {
      height: 309px !important;
    }

    :nth-child(5) {
      width: 347px !important;
    }

    :nth-child(11) {
      height: 309px !important;
    }

    :nth-child(16) {
      width: 347px !important;
    }
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1299px) {
  .gallery-container {
    img {
      height: 170px;
      width: 195px;
    }

    :nth-child(3) {
      height: 362px !important;
    }

    :nth-child(5) {
      width: 412px !important;
    }

    :nth-child(11) {
      height: 362px !important;
    }

    :nth-child(16) {
      width: 412px !important;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .gallery-container {
    img {
      width: 28rem;
      height: 25rem;
    }

    :nth-child(3) {
      grid-area: 1 / 3 / 3 / 4;
      height: 52.5rem !important;
    }

    :nth-child(5) {
      grid-area: 2 / 1 / 3 / 3;
      width: 58.5rem !important;
    }

    :nth-child(11) {
      grid-area: 4 / 1 / 6 / 2;
      height: 52.5rem !important;
    }

    :nth-child(16) {
      grid-area: 5 / 3 / 6 / 5;
      width: 58.5rem !important;
    }
  }
}
