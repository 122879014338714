.cardHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  // max-width: 800px;
  margin-top: 150px;
  padding-bottom: 150px;
  @media (max-width: 985px) {
    flex-direction: column;
    gap: 25px;
    margin-top: 100px;
  }
}

.cards {
  width: 300px;
  height: 475px;
  @media (max-width: 985px) {
    width: 400px;
    height: 550px;
  }
  @media (max-width: 500px) {
    width: 380px;
    height: 500px;
    padding: 0 30px;
  }
}

.card-img {
  width: 100%;
  height: 85%;
  margin-bottom: -3px;
  object-fit: cover;
  // @media (max-width: 400px) {
  //   width: 90%;
  // }
}

.bridal-img {
  object-position: center -1rem;
}

.sfx-img {
  object-position: center -5rem;
}

.label {
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
}

.label p {
  color: #17181c;
  font-size: 2.8rem;
  font-weight: 700;
}
