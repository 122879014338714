header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8rem 0 4rem;
  width: 100vw;
  position: relative;

  @media only screen and (min-width: 250px) and (max-width: 999px) {
    padding: 0 24px 0 8px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 3999px) {
    padding: 0 54px 0 8px;
  }
  @media (min-width: 4000px) {
    padding: 0 104px 0 8px;
  }

  .logo {
    height: 15rem;
    width: 15rem;

    @media only screen and (max-width: 399px) {
      width: 60px;
      height: 60px;
    }
    @media only screen and (min-width: 400px) and (max-width: 499px) {
      width: 75px;
      height: 75px;
    }
    @media only screen and (min-width: 500px) and (max-width: 699px) {
      width: 80px;
      height: 80px;
    }
    @media only screen and (min-width: 700px) and (max-width: 899px) {
      width: 94px;
      height: 94px;
    }
  }

  .menu-icon {
    height: 3rem;
    width: 3.2rem;

    @media only screen and (max-width: 399px) {
      width: 16px;
      height: 14px;
    }
    @media only screen and (min-width: 400px) and (max-width: 499px) {
      width: 20px;
      height: 18px;
    }
    @media only screen and (min-width: 500px) and (max-width: 699px) {
      width: 22px;
      height: 19px;
    }
    @media only screen and (min-width: 700px) and (max-width: 899px) {
      width: 22px;
      height: 20px;
    }
  }
}

@media only screen and (max-width: 999px) {
  #standard {
    display: none;
  }

  .menu-icon,
  #responsive,
  .responsive-container,
  .menu-container {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  #standard {
    display: block;
  }

  #responsive,
  .responsive-container,
  .menu-icon,
  .menu-container {
    display: none;
  }
}
