.sfxPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sfx-container {
  padding-top: 120px;

  img {
    border-color: var(--red);
  }

  img:nth-child(3) {
    object-position: center top;
  }

  img:nth-child(5) {
    object-position: center -21rem;
  }

  img:nth-child(16) {
    object-position: center;
  }
}

/* Media Queries */

@media only screen and (max-width: 299px) {
  .sfx-container {
    padding: 120px 4.8rem 0;

    img:nth-child(11),
    img:nth-child(13) {
      object-position: center top;
    }
  }
}

@media only screen and (max-width: 1299px) {
  .sfx-container {
    img:nth-child(5) {
      object-position: center 33%;
    }
  }
}
