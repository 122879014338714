.section-divider {
  width: 102.8%;
  height: 20.6rem;
  background-color: var(--bg-color);
  position: relative;

  @media (max-width: 999px) {
    display: none;
  }
}

.hero-divider {
  transform: rotate(-5deg);
  border-top: 8px solid var(--gold);
  top: -7rem;

  @media (max-width: 999px) {
    display: none;
  }
  @media only screen and (min-width: 1700px) {
    transform: rotate(-4deg);
  }
  @media only screen and (min-width: 1700px) and (max-width: 2099px) {
    top: -8rem;
  }
}

.about-divider {
  transform: rotate(4deg);
  border-bottom: 8px solid var(--gold);
  top: 31rem;

  @media (max-width: 999px) {
    display: none;
  }
  @media (min-width: 5000px) {
    top: 10rem;
    transform: rotate(3deg);
  }
}

.homePage {
  width: 100%;
  position: relative;

  .header {
    position: absolute !important;
  }
}

.hero-header {
  position: absolute;
  z-index: 2;
}
