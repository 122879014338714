.bridal-container,
.makeup-container,
.sfx-container {
  .page-heading-container {
    align-self: flex-start;
  }

  .homePage {
    .page-heading-container {
      align-self: center;
      text-align: center;
    }
  }
}

.homePage {
  .section-title,
  .headline,
  .subtitle {
    text-align: center;
  }
}

.section-title {
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;
  align-items: center;
  color: #686c7e;

  @media (max-width: 999px) {
    font-size: 2.8rem;
  }
  @media (min-width: 2000px) {
    font-size: 1.6rem;
  }
}

.headline {
  margin: 8px 0;
  color: var(--white);
  font-weight: 700;
  font-size: 4rem;
  text-transform: capitalize;

  @media (max-width: 999px) {
    font-size: 4.8rem;
    margin: 6px 0;
  }
  @media (min-width: 2000px) {
    font-size: 3.3rem;
  }
}

.subtitle {
  color: var(--white);
  font-size: 3rem;
  line-height: 152%;
  font-weight: 400;

  @media (max-width: 999px) {
    font-size: 3.3rem;
  }
  @media (min-width: 2000px) {
    font-size: 2.3rem;
  }
}
