.responsive-container {
  z-index: 2;
  #responsive {
    width: 100vw;
    background-color: var(--bg-color);
    height: 60vw;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8.8rem 8.8rem 0 15rem;
    z-index: 5;

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      list-style-type: none;
      gap: 3.2rem;

      h2,
      .nav-link:link,
      .nav-link:visited {
        font-size: 4rem;
        color: var(--white);
        font-weight: 500;
        padding-bottom: 1.4rem;
        padding-right: 4.8rem;
        border-bottom: solid 1px var(--white);
      }

      a {
        text-decoration: none;
      }
    }
    .close-btn {
      color: var(--white);
      z-index: 5;
      width: 3rem;
      height: 3rem;
      transform: translateX(8px) translateY(-8px);
    }
  }
}

/* Media Queries */

@media only screen and (max-width: 299px) {
  .responsive-container {
    #responsive {
      height: 160vw;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .responsive-container {
    #responsive {
      height: 120vw;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 499px) {
  .responsive-container {
    #responsive {
      ul {
        gap: 18px;
      }
    }
  }
}

@media only screen and (max-width: 399px) {
  .responsive-container {
    #responsive {
      padding: 36px 20px 0 36px;

      .close-btn {
        width: 14px;
        height: 14px;
      }
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
  .responsive-container {
    #responsive {
      height: 90vw;
      padding: 36px 20px 0 48px;
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 699px) {
  .responsive-container {
    #responsive {
      .close-btn {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media only screen and (max-width: 499px) {
  .responsive-container {
    #responsive {
      .close-btn {
        transform: translateY(-8px);
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  .responsive-container {
    #responsive {
      padding: 48px 32px 0 60px;

      ul {
        gap: 24px;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 599px) {
  .responsive-container {
    #responsive {
      height: 85vw;

      ul {
        h2,
        .nav-link:link,
        .nav-link:visited {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 699px) {
  .responsive-container {
    #responsive {
      height: 80vw;
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 799px) {
  .responsive-container {
    #responsive {
      height: 75vw;

      ul {
        h2,
        .nav-link:link,
        .nav-link:visited {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 899px) {
  .responsive-container {
    #responsive {
      .close-btn {
        width: 22px;
        height: 20px;
      }
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 899px) {
  .responsive-container {
    #responsive {
      height: 70vw;

      ul {
        h2,
        .nav-link:link,
        .nav-link:visited {
          font-size: 22px;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 999px) {
  .responsive-container {
    #responsive {
      height: 68vw;
    }
  }
}
